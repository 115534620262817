import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/app": [8,[2]],
		"/app/bank_accounts/create": [11,[2]],
		"/app/bank_accounts/[...slug]/bank_transactions/create": [10,[2]],
		"/app/bank_accounts/[...slug]": [9,[2]],
		"/app/bank_keys": [12,[2]],
		"/app/banking/bank_devices": [13,[2]],
		"/app/banking/bank_payrolls/create": [15,[2]],
		"/app/banking/bank_payrolls/[...slug]": [14,[2]],
		"/app/banking/bank_recipients/create": [17,[2]],
		"/app/banking/bank_recipients/[...slug]": [16,[2]],
		"/app/cartolas": [18,[2]],
		"/app/companies/new": [19,[2]],
		"/app/complete-information": [20,[2]],
		"/app/dashboard": [21,[2]],
		"/app/erp/payouts/company_refunds": [22,[2]],
		"/app/erp/payouts/credit_notes": [23,[2]],
		"/app/erp/payouts/expense_reports/[...slug]": [24,[2]],
		"/app/erp/payouts/imprest_funds": [25,[2]],
		"/app/erp/payouts/imprest_funds/new": [27,[2]],
		"/app/erp/payouts/imprest_funds/[...slug]": [26,[2]],
		"/app/erp/payouts/payables": [28,[2]],
		"/app/erp/payouts/payables/approved": [30,[2]],
		"/app/erp/payouts/payables/assignable": [31,[2]],
		"/app/erp/payouts/payables/declined": [32,[2]],
		"/app/erp/payouts/payables/mine": [33,[2]],
		"/app/erp/payouts/payables/new": [34,[2]],
		"/app/erp/payouts/payables/paid": [35,[2]],
		"/app/erp/payouts/payables/pay": [36,[2]],
		"/app/erp/payouts/payables/pending": [37,[2]],
		"/app/erp/payouts/payables/scheduled": [38,[2]],
		"/app/erp/payouts/payables/[...slug]": [29,[2]],
		"/app/invoices/sii_account": [39,[2]],
		"/app/payment": [40,[2]],
		"/app/settings/api_keys": [41,[2,3]],
		"/app/settings/bulk_load": [42,[2,3]],
		"/app/settings/company": [43,[2,3]],
		"/app/settings/contracts": [44,[2,3]],
		"/app/settings/integrations": [45,[2,3]],
		"/app/settings/integrations/add": [46,[2,3]],
		"/app/settings/kyc": [47,[2,3]],
		"/app/settings/payments": [48,[2,3]],
		"/app/settings/profile": [49,[2,3]],
		"/app/settings/sii": [50,[2,3]],
		"/app/settings/team": [51,[2,3]],
		"/app/transactions": [52,[2]],
		"/app/vendor_cards/activate_success": [55,[2]],
		"/app/vendor_cards/activate": [54,[2]],
		"/app/vendor_cards/create": [56,[2]],
		"/app/vendor_cards/[...slug]": [53,[2]],
		"/app/vendors": [57,[2]],
		"/app/vendors/[...slug]/details": [58,[2,4]],
		"/app/vendors/[...slug]/general": [59,[2,4]],
		"/app/vendors/[...slug]/rules": [60,[2,4]],
		"/confirm-refund": [61],
		"/impersonate": [62],
		"/invitation": [63,[5]],
		"/invitation/invalid": [64,[5]],
		"/invitation/user": [65,[5]],
		"/jobs": [66],
		"/landing/transfers": [67],
		"/login": [68],
		"/logout": [69],
		"/mfa-notify": [70],
		"/onboarding": [71,[6]],
		"/pay": [72],
		"/recover-password": [73],
		"/refunds": [74],
		"/terms": [75],
		"/transfers": [76],
		"/tyc": [77],
		"/verify": [78]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';